<template>
  <div>
    <el-card class="mt ml mr">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="login"
        label-width="12%"
      >
        <!-- <el-form-item prop="email" class="name mt ml">
          <span>{{ $t("change.email") }}</span>
          <el-input v-model="ruleForm.email" placeholder="" class="input1">
          </el-input>
        </el-form-item> -->
        <el-form-item
          prop="currentPassword"
          class="name mt"
          :label="this.$t('change.currentPassword')"
        >
          <el-input
            v-model="ruleForm.currentPassword"
            placeholder=""
            show-password
            class="input2"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          prop="newPassword"
          class="name mt"
          :label="this.$t('change.newPassword')"
        >
          <el-input
            v-model="ruleForm.newPassword"
            placeholder=""
            show-password
            class="input3"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          prop="confirmPasswords"
          class="name mt"
          :label="this.$t('change.confirmPasswords')"
        >
          <el-input
            v-model="ruleForm.confirmPasswords"
            placeholder=""
            show-password
            class="input4"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="button" @click="preservation">{{
            $t("change.preservation")
          }}</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { changePassword } from "@/helper/api";
export default {
  data() {
    // var email = (rule, value, callback) => {
    //   const reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
    //   if (reg.test(value)) {
    //     callback();
    //   } else {
    //     callback(new Error(this.$t("login.emailreg")));
    //   }
    // };
    var currentPassword = (rule, value, callback) => {
      const reg = /^.{6,}$/;
      if (reg.test(value)) {
        callback();
      } else {
        callback(new Error(this.$t("login.passwordreg")));
      }
    };
    var newPassword = (rule, value, callback) => {
      const reg = /^.{6,}$/;
      if (!reg.test(value)) {
        callback(new Error(this.$t("login.passwordreg")));
      } else if (value === this.ruleForm.currentPassword) {
        callback(new Error(this.$t("login.passwordregs")));
      } else {
        callback();
      }
    };
    var confirmPasswords = (rule, value, callback) => {
      if (value === this.ruleForm.newPassword) {
        callback();
      } else {
        callback(new Error(this.$t("register.confirmPasswordreg")));
      }
    };
    return {
      ruleForm: {
        // email: "",
        currentPassword: "",
        newPassword: "",
        confirmPasswords: "",
      },
      rules: {
        // email: [
        //   {
        //     required: true,
        //     message: this.$t("login.emails"),
        //     trigger: "blur",
        //   },
        //   { validator: email, trigger: "blur" },
        // ],
        currentPassword: [
          {
            required: true,
            message: this.$t("login.passwords"),
            trigger: "blur",
          },
          { validator: currentPassword, trigger: "blur" },
        ],
        newPassword: [
          {
            required: true,
            message: this.$t("login.passwords"),
            trigger: "blur",
          },
          { validator: newPassword, trigger: "blur" },
        ],
        confirmPasswords: [
          {
            required: true,
            message: this.$t("login.passwords"),
            trigger: "blur",
          },
          { validator: confirmPasswords, trigger: "blur" },
        ],
      },
    };
  },
  created() {
    //  console.log(localStorage.getItem("data"));
  },
  methods: {
    preservation() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          changePassword("PUT", "users/change-password", {
            currentPassword: this.ruleForm.currentPassword,
            newPassword: this.ruleForm.newPassword,
            confirmPassword: this.ruleForm.confirmPasswords,
          }).then((res) => {
            console.log(res);
            if (res.success == true) {
              this.$message({
                message: this.$t("change.successfully"),
                type: "success",
              });
              localStorage.clear();
              this.$router.push("/login");
            } else {
              this.$message({
                message: res.message,
                type: "error",
              });
            }
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.name .el-input__inner {
  width: 30%;
}
/* .name .input1 {
  width: 400px;
}
.name .input2 {
  width: 324px;
}
.name .input3 {
  width: 346px;
}
.name .input4 {
  width: 282px;
}
.name span {
  margin-right: 10px;
  font-size: 13px;
  font-weight: 700;
} */
.button {
  width: 218px;
  height: 51px;
  background: #ff0a0a;
  border-radius: 10px;
  color: #fff;
  font-size: 18px;
  margin: 30px 0 20px -5%;
}
</style>